import AceEditor from "react-ace";
import ace from 'ace-builds/src-noconflict/ace';
import "ace-builds/src-noconflict/mode-javascript";
import jsonWorker from 'ace-builds/src-noconflict/worker-javascript';
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sqlserver";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-r";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-kotlin";
import "ace-builds/src-noconflict/mode-dockerfile";
import "ace-builds/src-noconflict/mode-rust";
import "ace-builds/src-noconflict/mode-powershell";
import "ace-builds/src-noconflict/mode-verilog";
import "ace-builds/src-noconflict/mode-vue";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools"

export const getModeByType = (type) => {
    if (type == 5) { // C
        return ("c_cpp");
    } else if (type == 6 || type == 20 || type == 27) {// Java
        return ("java");
    } else if (type == 7 || type == 18) { // C Sharp
        return ("csharp");
    } else if (type == 8 || type == 25) { // Python
        return ("python");
    } else if (type == 9) { // SQL Server
        return ("sqlserver");
    } else if (type == 10) { // Bash
        return ("sh");
    } else if (type == 11 || type == 24 || type == 30) { // JavaScript
        return ("javascript");
    } else if (type == 12) { // C++
        return ("c_cpp");
    } else if (type == 13) { // PHP
        return ("php");
    } else if (type == 14) { // React
        return ("jsx");
    } else if (type == 16) { // Go
        return ("golang");
    } else if (type == 15) { // kotlin
        return ("kotlin");
    } else if (type == 22) { // Ruby
        return ("ruby");
    } else if (type == 23) { // R
        return ("r");
    } else if (type == 26) { // dockerfile
        return ("dockerfile");
    }else if(type == 29){ // Vue 
	return ("html");
    }
    else if(type == 30){ // Vue 
	return ("typescript");
    }
    else if(type == 31){ // Vue 
	return ("html");
    }else if(type == 33){ // R 
    	return ("verilog");
    }
    else if(type == 35){//ps
    	return ("powershell");
    }else if(type == 36){//rust
    	return ("rust");
    }
    
    
}

const Editor = ({mode,value,valueChanged, height = "100%", showTestCases = false , style={}})=>{
    return (
        <AceEditor
            placeholder="Happy Coding!"
            mode={getModeByType(mode)}
            theme="solarized_dark"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            height={showTestCases ? `calc(${height} - 45px)` : height}
            width={'100%'}
            style={{borderRadius: '0px', padding: "5px",...style}}
            value={value}
            readOnly={true}
            onChange={valueChanged ? e => valueChanged(e) : ()=>{}}
            setOptions={{
                 highlightActiveLine: false,
                highlightGutterLine: false, showLineNumbers: true, tabSize: 2
            }}/>
    )
}

export default Editor